<template>
  <div>
    <a-space>
    <EmployeeSelector :single="true" @change="onSelectEmployee" :disabled="disabled">
      <a-button :disabled="disabled" type="primary">选择</a-button>
    </EmployeeSelector>
       <span v-if="type === 'plan'"> 
        检查组长和检查组成员
       </span>
              <span v-if="type === 'work'"> 
                检查组长、检查组成员、受检单位成员和受检项目人员
       </span>
                   <span v-if="type === 'rectify'">
                                复查人、整改人、整改负责人和检查负责人
           </span>
      </a-space>

    <Padding />

    <a-table bordered :data-source="list" :pagination="false" rowKey="userId">
      <a-table-column title="姓名" data-index="userName" align="center" />
      <a-table-column title="类型" align="center">
        <template slot-scope="text">
          <span v-if="text.type === 'groupLeader'">检查组长</span>
          <span v-if="text.type === 'groupMember'">检查组组员</span>
          <span v-if="text.type === 'unitMember'">受检单位成员</span>
          <span v-if="text.type === 'projectMember'">受检项目人员</span>

          <span v-if="text.type === 'doubleChecker'">复查人</span>
          <span v-if="text.type === 'inspectManager'">检查负责人</span>
          <span v-if="text.type === 'rectifyManager'">整改负责人</span>
          <span v-if="text.type === 'rectifier'">整改人</span>

        </template>
      </a-table-column>
      <a-table-column title="职责分工" data-index="duty" />
      <a-table-column title="操作" align="center" v-if="!disabled">
        <template slot-scope="text, record, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>

    <a-modal
      :title="selected.userName  ? selected.userName : ''"
      :visible="visible"
      @cancel="cancel"
      :footer="null"
      width="600px"
    >
      <a-form :colon="false" @submit="handleSubmit" :form="form">
        <a-form-item label="类型">
          <a-radio-group
            v-decorator="[
              'type',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择',
                  },
                ],
              },
            ]"
          >
            <a-radio v-for="item in typeList" :key="item.value" :value="item.value">{{ item.name }}</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="职责">
          <a-textarea
            v-decorator="[
                  'duty',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
          />
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">关闭</a-button>
            <a-button htmlType="submit" type="primary">添加</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import EmployeeSelector from "@/components/employee-selector";

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "plan"
    }
  },

  components: {
    EmployeeSelector
  },

  data() {
    return {
      typeList: [
        { name: "检查组长", value: "groupLeader" },
        {
          name: "检查组组员",
          value: "groupMember"
        }
      ],
      selected: {},
      form: this.$form.createForm(this),
      visible: false
    };
  },

  mounted() {
    this.setTypeList();
  },

  watch: {
    type() {
      this.setTypeList();
    }
  },

  methods: {
    setTypeList() {
      if (this.type === "work") {
        this.typeList = [
          { name: "检查组长", value: "groupLeader" },
          {
            name: "检查组组员",
            value: "groupMember"
          },
          {
            name: "受检单位成员",
            value: "unitMember"
          },
          // {
          //   name: '受检项目人员',
          //   value: 'projectMember'
          // }
        ];
      } else if (this.type === "rectify") {
        this.typeList = [
          { name: "复查人", value: "doubleChecker" },
          {
            name: "检查负责人",
            value: "inspectManager"
          },
          {
            name: "整改负责人",
            value: "rectifyManager"
          },
             {
            name: "整改人",
            value: "rectifier"
          }
        ];
      } else {
        this.typeList = [
          { name: "检查组长", value: "groupLeader" },
          {
            name: "检查组组员",
            value: "groupMember"
          }
        ];
      }
    },

    onSelectEmployee(list) {
      if (list.length > 0) {
        if (this.list.find(item => item.userId === list[0].userId)) {
          this.$message.error("请勿重复选择");
          return;
        }
        this.selected = {
          userName: list[0].name,
          userId: list[0].userId
        };
        this.visible = true;
      }
    },
    remove(index) {
      this.list.splice(index, 1);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);
          this.list.push({
            ...this.selected,
            ...values,
            parentType: this.type
          });
          this.cancel();
        }
      });
    },

    cancel() {
      this.visible = false;
      this.selected = {};
      this.form.resetFields();
    }
  }
};
</script>


<style lang="less" scoped>
.footer {
  padding: 80px 0;
}
</style>