var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 14 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"检查名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', 
                { 
                  initialValue: _vm.detail.name,
                  rules: [{ required: true, message: '请输入' }] },]),expression:"['name', \n                { \n                  initialValue: detail.name,\n                  rules: [{ required: true, message: '请输入' }] },]"}],attrs:{"disabled":_vm.disabled}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"检查类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'type',
                { 
                  initialValue: _vm.detail.type,
                  rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'type',\n                { \n                  initialValue: detail.type,\n                  rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":_vm.disabled}},_vm._l((_vm.qualityInspectType),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"检查实施单位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'unitId',
                {
                  initialValue: _vm.detail.unitId,
                   rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'unitId',\n                {\n                  initialValue: detail.unitId,\n                   rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":_vm.disabled}},_vm._l((_vm.organizationList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.uniqueName))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"检查时间段"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'date',
            { 
                  initialValue: _vm.initialValueDate,
              rules: [{ required: true, message: '请选择！' }] },
          ]),expression:"[\n            'date',\n            { \n                  initialValue: initialValueDate,\n              rules: [{ required: true, message: '请选择！' }] },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":_vm.disabled,"placeholder":[]}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"受检单位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'inspectUnitId',
                {
                  initialValue: _vm.detail.inspectUnitId,
                   rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'inspectUnitId',\n                {\n                  initialValue: detail.inspectUnitId,\n                   rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":_vm.disabled},on:{"change":_vm.onChangeCompany}},_vm._l((_vm.organizationList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.uniqueName))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否填写受检项目"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'isManual',
                {
                  initialValue: _vm.detail.isManual || false,
                  valuePropName: 'checked',
                    },
              ]),expression:"[\n                'isManual',\n                {\n                  initialValue: detail.isManual || false,\n                  valuePropName: 'checked',\n                    },\n              ]"}],attrs:{"checked-children":"是","un-checked-children":"否","disabled":_vm.disabled}})],1)],1),(_vm.form.getFieldValue('isManual'))?_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"受检项目"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['inspectProjectName', 
                { 
                  initialValue: _vm.detail.inspectProjectName,
                  rules: [{ required: true, message: '请输入' }] },]),expression:"['inspectProjectName', \n                { \n                  initialValue: detail.inspectProjectName,\n                  rules: [{ required: true, message: '请输入' }] },]"}],attrs:{"disabled":_vm.disabled}})],1)],1):_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"受检项目","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('ProjectSelector',{attrs:{"disabled":_vm.disabled,"inspectUnitId":_vm.form.getFieldValue('inspectUnitId') || _vm.detail.inspectUnitId},on:{"change":_vm.onSelectProject}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":"","disabled":_vm.disabled}},[_vm._v(_vm._s(_vm.selectedProject.name ? _vm.selectedProject.name : '选择'))])],1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"检查实施依据","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'evidence',
                { 
                  initialValue: _vm.detail.evidence,
                  rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'evidence',\n                { \n                  initialValue: detail.evidence,\n                  rules: [{ required: true, message: '请输入！' }] },\n              ]"}],attrs:{"disabled":_vm.disabled}})],1)],1)],1),_c('a-divider'),_c('a-row',[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"参与检查人员","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('Employee',{attrs:{"list":_vm.memberList,"disabled":_vm.disabled}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"检查计划","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'planContent',
                {
                  initialValue: _vm.detail.planContent,
                   rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'planContent',\n                {\n                  initialValue: detail.planContent,\n                   rules: [{ required: true, message: '请输入！' }] },\n              ]"}],attrs:{"disabled":_vm.disabled}})],1)],1)],1),_c('div',{staticClass:"footer center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.saveLoading,"disabled":_vm.disabled}},[_vm._v(_vm._s(_vm.detail.id && _vm.detail.status === 'rejected' ? '重新发起审批' : '发起审批'))]),_c('a-button',{attrs:{"loading":_vm.saveLoading,"disabled":_vm.disabled},on:{"click":_vm.onSave}},[_vm._v("保存")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }