<template>
  <a-card class="container">
    <a-form
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
      :colon="false"
      @submit="handleSubmit"
      :form="form"
    >
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="检查名称">
            <a-input
              :disabled="disabled"
              v-decorator="['name', 
                  { 
                    initialValue: detail.name,
                    rules: [{ required: true, message: '请输入' }] },]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="检查类型">
            <a-select
              :disabled="disabled"
              style="width: 100%"
              v-decorator="[
                  'type',
                  { 
                    initialValue: detail.type,
                    rules: [{ required: true, message: '请选择！' }] },
                ]"
            >
              <a-select-option
                v-for="item in qualityInspectType"
                :key="item.value"
                :value="item.value"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="检查实施单位">
            <a-select
              :disabled="disabled"
              style="width: 100%"
              v-decorator="[
                  'unitId',
                  {
                    initialValue: detail.unitId,
                     rules: [{ required: true, message: '请选择！' }] },
                ]"
            >
              <a-select-option
                v-for="item in organizationList"
                :key="item.id"
                :value="item.id"
              >{{ item.uniqueName }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="检查时间段">
            <a-range-picker
              :disabled="disabled"
              :placeholder="[]"
              v-decorator="[
              'date',
              { 
                    initialValue: initialValueDate,
                rules: [{ required: true, message: '请选择！' }] },
            ]"
              style="width: 100%"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="受检单位">
            <a-select
              :disabled="disabled"
              style="width: 100%"
              @change="onChangeCompany"
              v-decorator="[
                  'inspectUnitId',
                  {
                    initialValue: detail.inspectUnitId,
                     rules: [{ required: true, message: '请选择！' }] },
                ]"
            >
              <a-select-option
                v-for="item in organizationList"
                :key="item.id"
                :value="item.id"
              >{{ item.uniqueName }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="是否填写受检项目">
            <a-switch
              checked-children="是"
              un-checked-children="否"
              v-decorator="[
                  'isManual',
                  {
                    initialValue: detail.isManual || false,
                    valuePropName: 'checked',
                      },
                ]"
              :disabled="disabled"
            ></a-switch>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24" v-if="form.getFieldValue('isManual')">
          <a-form-item label="受检项目">
            <a-input
              :disabled="disabled"
              v-decorator="['inspectProjectName', 
                  { 
                    initialValue: detail.inspectProjectName,
                    rules: [{ required: true, message: '请输入' }] },]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24" v-else>
          <a-form-item label="受检项目" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
            <ProjectSelector
              @change="onSelectProject"
              :disabled="disabled"
              :inspectUnitId="form.getFieldValue('inspectUnitId') || detail.inspectUnitId"
            >
              <a-button
                block
                style="text-align: left"
                :disabled="disabled"
              >{{ selectedProject.name ? selectedProject.name : '选择' }}</a-button>
            </ProjectSelector>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="检查实施依据" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
            <a-textarea
              :disabled="disabled"
              v-decorator="[
                  'evidence',
                  { 
                    initialValue: detail.evidence,
                    rules: [{ required: true, message: '请输入！' }] },
                ]"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-divider />

      <a-row>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="参与检查人员" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
            <Employee :list="memberList" :disabled="disabled" />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="检查计划" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
            <a-textarea
              :disabled="disabled"
              v-decorator="[
                  'planContent',
                  {
                    initialValue: detail.planContent,
                     rules: [{ required: true, message: '请输入！' }] },
                ]"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <div class="footer center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
          <a-button
            htmlType="submit"
            type="primary"
            :loading="saveLoading"
            :disabled="disabled"
          >{{ detail.id && detail.status === 'rejected' ? '重新发起审批' : '发起审批' }}</a-button>
          <a-button :loading="saveLoading" :disabled="disabled" @click="onSave">保存</a-button>
        </a-space>
      </div>
    </a-form>
  </a-card>
</template>

<script>
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";
import request from "@/api/request";
import moment from "moment";
import { mapGetters } from "vuex";

import Employee from "./employee.vue";
import ProjectSelector from "./project-selecor.vue";

function save(data) {
  return request({
    url: "/office-service/quality/inspect/plan/save",
    method: "post",
    data
  });
}

function modify(data) {
  return request({
    url: "/office-service/quality/inspect/plan/modify",
    method: "post",
    data
  });
}

function send(data) {
  return request({
    url: "/office-service/quality/inspect/plan/raiseProgress",
    method: "post",
    data
  });
}

function reSend(data) {
  return request({
    url: "/office-service/quality/inspect/plan/resubmit",
    method: "post",
    data
  });
}

export default {
  props: {
    detail: {
      type: Object,
      default: () => ({})
    }
  },

  mixins: [organization, watermark],

  components: {
    Employee,
    ProjectSelector
  },

  data() {
    return {
      form: this.$form.createForm(this),

      selectedProject: {},
      memberList: [],

      saveLoading: false
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    qualityInspectType() {
      return this.findDataDict("qualityInspectType");
    },

    disabled() {
      if (!this.detail.status) {
        return false;
      }
      if (
        this.detail.status === "created" ||
        this.detail.status === "resubmited"
      ) {
        return false;
      }
      return true;
    },
    initialValueDate() {
      if (this.detail.startTime) {
        return [moment(this.detail.startTime), moment(this.detail.endTime)];
      } else {
        return undefined;
      }
    }
  },

  watch: {
    detail: {
      handler() {
        this.initData();
      },
      deep: true
    }
  },

  mounted() {
    this.initData();
  },

  methods: {
    initData() {
      if (this.detail.id) {
        this.form.setFieldsValue({
          isManual: !!this.detail.isManual
        });
        if (!this.detail.isManual) {
          this.selectedProject = {
            name: this.detail.inspectProjectName,
            id: this.detail.inspectProjectId
          };
        }
        if (Array.isArray(this.detail.memberList)) {
          this.memberList = this.detail.memberList;
        }
      }
    },
    onSelectProject(project) {
      console.log(project);
      this.selectedProject = project;
    },

    onChangeCompany(e) {
      console.log(e);
      if (this.form.getFieldValue("isManual")) {
        this.form.setFieldsValue({ inspectProjectName: undefined });
      } else {
        this.selectedProject = {};
      }
    },

    onSave() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.control("save", values);
        }
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log("values", values);
        if (!err) {
          this.control("send", values);
        }
      });
    },

    control(type, values) {
      let ask;
      if (type === "save") {
        if (this.detail.id) {
          ask = modify;
        } else {
          ask = save;
        }
      }
      if (type === "send") {
        ask = send;

        if (this.detail.status === "rejected") {
          // 如果被驳回
          ask = reSend;
        } else {
          // 不是被驳回，能点此按钮，要么是一开始就发审批，要么是修改后发起审批
          ask = send;
        }
      }

      console.log(values);

      if (!values.isManual) {
        if (!this.selectedProject.name) {
          this.$message.error("请选择受检项目");
          return;
        }
      }

      if (this.memberList.length === 0) {
        this.$message.error("请增加参与检查人员");
        return;
      }

      this.saveLoading = true;

      const startTime = values.date[0].format("YYYY-MM-DD");
      const endTime = values.date[1].format("YYYY-MM-DD");

      ask({
        ...this.detail,
        ...values,

        unitName: this.organizationList.find(item => item.id === values.unitId)
          .uniqueName,

        startTime,
        endTime,
        date: undefined,

        inspectUnitName: this.organizationList.find(
          item => item.id === values.inspectUnitId
        ).uniqueName,

        inspectProjectId:
          !values.isManual && this.selectedProject.id
            ? this.selectedProject.id
            : undefined,

        inspectProjectName:
          !values.isManual && this.selectedProject.name
            ? this.selectedProject.name
            : values.inspectProjectName,

        memberList: this.memberList
      })
        .then(() => {
          this.$close(this.$route.path);
        })
        .finally(() => {
          this.saveLoading = false;
        });
    }
  }
};
</script>


<style lang="less" scoped>
.container {
  padding-top: 24px;
}
.footer {
  padding: 80px 0;
}
</style>